import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';

const TaskList = () => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const unsubscribeSites = onSnapshot(collection(db, 'queue-home'), (snapshot) => {
      let newTasks = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        created: doc.data().created?.toDate() || null
      }));

      newTasks = newTasks.map(task => {
        if (task.attempts && task.attempts.length > 0) {
          task.firstAttemptStart = task.attempts[0].start.toMillis();
          task.lastAttemptStart = task.attempts[task.attempts.length - 1].start.toMillis();
          task.lastAttemptEnd = task.attempts[task.attempts.length - 1].end.toMillis();
          task.timeToFirstAttempt = Math.round((task.firstAttemptStart - task.created.getTime())/1000);
          task.finalAttemptLength = Math.round((task.lastAttemptEnd - task.lastAttemptStart)/1000);
          if (task.lastAttemptEnd) {
            task.timeToSortBy = task.lastAttemptEnd;
            task.lastAction = task.attempts[task.attempts.length - 1].end;
          } else {
            task.timeToSortBy = task.lastAttemptStart;
          }

          if (task.attempts[task.attempts.length - 1].result === 'failed' && task.attempts[task.attempts.length - 1].reason) {
            task.failedReason = task.attempts[task.attempts.length - 1].reason;
          }
          return task;
        } else {
          task.timeToSortBy = task.created.getTime();
          return task;
        }
      });

      newTasks.sort((a, b) => {
        if (a.timeToSortBy === null) return 1;
        if (b.timeToSortBy === null) return -1;
        return b.timeToSortBy - a.timeToSortBy;
      });

      setTasks(newTasks);
    });

    return () => {
      // Clean up sites subscription
      unsubscribeSites();
    };
  }, []); // Run useEffect only once when the component is mounted

  return (
    <Box sx={{ marginTop: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Created</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>ID</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>URL</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Attempts</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Time to First Attempt</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Last Attempt Length</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Last Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.map(task => (
            <TableRow
              key={task.id}
              sx={{
                backgroundColor: task.status === 'failed' || task.status === 'timeout' ? '#ffe8ed' : 
                                task.status === 'pending' || task.status === 'processing' ? '#fffde8' :
                                task.status === 'complete' ? '#e8ffe8' : 'inherit',
              }}
            >
              <TableCell>{task.created.toLocaleString()}</TableCell>
              <TableCell>{task.id}</TableCell>
              <TableCell>{task.url}</TableCell>
              <TableCell>
                {task.failedReason ? (
                  <Tooltip title={task.failedReason}>
                    <span>{task.status}</span>
                  </Tooltip>
                ) : (
                  task.status
                )}
              </TableCell>
              <TableCell>{task.attempts && task.attempts.length > 0 ? task.attempts.length : ''}</TableCell>
              <TableCell>{task.timeToFirstAttempt ? `${task.timeToFirstAttempt}s` : ''}</TableCell>
              <TableCell>{task.finalAttemptLength ? `${task.finalAttemptLength}s` : ''}</TableCell>
              <TableCell>{task.lastAction ? task.lastAction.toDate().toLocaleString() : ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default TaskList;
