import React, {useState, useRef, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';

export default function ChatSubmitBox({ onSubmit, state = ""}) {
  const [value, setValue] = useState('');
  const [ disabled, setDisabled ] = useState(false);
  const inputRef = useRef(null);
  const setInputRef = (element) => {
    if (element) {
      inputRef.current = element.children[0]; // Getting the actual input element
    }
  };

  const placeHolder = 
    state === "thinking" ? 
      'Thinking...' : 
      state === "searching" ? 
        "Searching..." : 
      'I want...';

  useEffect(() => {
    if (state === "thinking" || state === "searching") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [state]);

  useEffect(() => {
    if (!disabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [disabled]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSubmit(value);
      setValue('');
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        position: 'sticky', 
        bottom: 24, 
        width: '100%',
    }}>
      <Paper
        component="form"
        onSubmit={(e) => e.preventDefault()}
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', boxShadow: 4, width: '80%' }}
      >
        <InputBase
          ref={setInputRef}
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeHolder}
          inputProps={{ 'aria-label': placeHolder}}
          multiline
          maxRows={8}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
        <IconButton onClick={() => { onSubmit(value); setValue(''); }} sx={{ p: '10px' }} aria-label="search" disabled={disabled}>
          {disabled ? <MoreHorizIcon /> : <SendIcon />}
        </IconButton>
      </Paper>
    </Box>
  );
}
