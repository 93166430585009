// Component for SearchReject

import React from 'react';

const SearchReject = (props) => {
  const { result } = props;

  return (
    <div class="searchReject">
      <a href={result.url}>
        <b>{result.title}</b> {result.type} ({result.reason})
      </a>
    </div>
  );
};

export default SearchReject;