import React from 'react';
import Chip from '@mui/material/Chip';

const SearchQueryStatement = (props) => {
  const { statement } = props;

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  const themedStatement =
    statement === undefined ? 
    "statement is undefined" : 
    <Chip label={statement} onDelete={handleDelete} />;

  return (
    <div class="statement">
      {themedStatement}
    </div>
  );
};

export default SearchQueryStatement;