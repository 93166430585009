import React, { useContext } from "react";
import { SearchContext } from "../../contexts/search.context";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CamDoLogo from "../../assets/images/stride-logo.svg";

const Header = () => {
  const { seg } = useContext(SearchContext);
  const title = seg
    ? `Find ${seg.charAt(0).toUpperCase() + seg.slice(1)} Tech`
    : "Find Better Ways";

  const handleNewSearch = () => {
    const url = `/s/`;
    window.open(url, "_blank").focus();
  };

  return (
    <AppBar position="fixed" color="inherit">
      <Toolbar>
        <a href="https://cam-do.com" target="_blank" rel="noopener noreferrer">
          <img height="32px" alt="CamDo" src={CamDoLogo} />
        </a>
        <Box ml={2}>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Box ml="auto">
          <Button color="primary" onClick={handleNewSearch}>
            New Search
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
