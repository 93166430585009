import React, { useContext } from 'react';
import { SearchContext } from '../../contexts/search.context';
import Button from '@mui/material/Button';

const SearchButton = ({ onClick, progress }) => {
  const { lastQuery } = useContext(SearchContext);

  const buttonStyle = {
    background: `linear-gradient(
      90deg, 
      #f09f54 ${progress}%, 
      #dedede ${progress}%100%
    )`,
  };

  const label = lastQuery.id ? "Search Again" : "Search Now";

  return (
    <Button 
      variant="contained" 
      sx={{marginBottom: "15px", marginTop: "10px"}}
      color="primary" 
      onClick={onClick}
      style={buttonStyle}
    >
      {label}
    </Button>
  );
};

export default SearchButton;