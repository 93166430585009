import React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';

export default function SearchChatBox() {
  return (
    <div class="searchChatBox">
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', boxShadow: 4 }}
      >
        <InputBase
          disabled
          sx={{ ml: 1, flex: 1 }}
          placeholder="(Search completed, chat locked)"
          inputProps={{ 'aria-label': 'chat and search locked' }}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SendIcon />
        </IconButton>
      </Paper>
    </div>
  );
}