import React from "react";
import { Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Construction.css"; // Ensure the CSS file includes the updated styles
import CamDoLogo from "../../assets/images/stride-logo.svg";

const Construction = () => {
  const navigate = useNavigate();

  const handleBegin = () => {
    navigate("/s/");
  };

  return (
    <Box className="construction-container">
      <Box className="overlay">
        <Box className="text-container">
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontFamily: "Gotham, Arial, sans-serif",
              maxWidth: "50vw",
              fontSize: {
                xs: "2rem",
                sm: "2rem",
                md: "2.5rem",
                lg: "3rem",
                xl: "3.5rem",
              },
              textAlign: "center",
            }}
          >
            Find the Best Construction Technology for any Problem
          </Typography>
          <Button variant="contained" size="large" onClick={handleBegin}>
            Begin
          </Button>
        </Box>
        <Box className="footer-container">
          <a
            href="https://cam-do.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img height="64px" alt="CamDo" src={CamDoLogo} />
          </a>
          <Typography variant="body1" sx={{ fontStyle: "italic" }} gutterBottom>
            Powered by CamDo
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Construction;
