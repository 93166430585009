import React from 'react';
import SearchResult from '../components/SearchResult';
import SearchReject from '../components/SearchReject';

const SearchResults = (props) => {
  const { results, scaffold } = props;

  const resultComponents = results && results.length > 0 ? results.map((result, key) => {
    return (<SearchResult key={key} result={result} />);
  }) : null;

  const rejectComponents = scaffold && scaffold.rejects && scaffold.rejects.length > 0 ? scaffold.rejects.map((result, key) => {
    return (<SearchReject key={key} result={result} />);
  }) : null;

  return (
    <div class="searchResults">
      <div class="panelHeader">Results</div>
      <div class="content">
        <div class="introduction">
          {scaffold && scaffold.introduction ? scaffold.introduction : null}
        </div>
        {resultComponents}
          {scaffold && scaffold.rejects ? 
            <div class="rejects searchResult">
              <div class="rejectsHeader">The following tools were also considered relevant but are not recommended based on the needs you shared.</div>
              {rejectComponents}
            </div>
          : null}
      </div>
    </div>
  );
};

export default SearchResults;