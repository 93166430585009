import React, { createContext, useEffect, useState } from "react";
import { auth } from "../utils/firebase";
import LogRocket from 'logrocket';

export const UserContext = createContext({
  setCurrentUser: () => null,
  currentUser: null,
});

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      let domain = "";
      if (user && user.displayName && user.uid && user.email && user?.email.length > 0) {
        domain = user.email.substring(
          user.email.indexOf("@") + 1
        );

        LogRocket.identify(user.uid, {
          name: user.displayName,
          email: user.email,
        });
      }
      setCurrentUser({user:user, domain: domain});
    });

    return () => unsubscribe();
  }, []);

  const value = { currentUser, setCurrentUser };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};