import React, { useState, useEffect } from 'react';
import { Box, Typography, Link } from '@mui/material';
import { collection, onSnapshot, doc } from 'firebase/firestore';
import { db } from '../../utils/firebase';

const Offering = ({ offering, crawlId, siteId }) => {
  const [pages, setPages] = useState([]);
  const [statements, setStatements] = useState([]);

  useEffect(() => {
    const pagesRef = collection(doc(db, 'sites', siteId, 'crawls', crawlId, 'offerings', offering.id), 'pages');
    const unsubscribePages = onSnapshot(pagesRef, (snapshot) => {
      const newPages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPages(newPages);
    });

    const statementsRef = collection(doc(db, 'sites', siteId, 'crawls', crawlId, 'offerings', offering.id), 'statements');
    const unsubscribeStatements = onSnapshot(statementsRef, (snapshot) => {
      const newStatements = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setStatements(newStatements);
    });

    return () => {
      unsubscribePages();
      unsubscribeStatements();
    };
  }, [siteId, crawlId, offering.id]);

  // Combine pages and statements based on URL
  const urls = [...new Set([...pages.map(p => p.url), ...statements.map(s => s.url)])];
  const combinedData = urls.map(url => ({
    url,
    statements: statements.filter(s => s.url === url),
  }));

  return (
    <Box sx={{ padding: 1, border: '1px solid #eee', marginBottom: 1 }}>
      <Typography variant="h6" color={offering.archived ? 'textSecondary' : 'textPrimary'}>
        {offering.name}
        {offering.archived && <i> (archived)</i>}
        : - ({offering.id})
      </Typography>
      <Typography variant="body2" color={offering.archived ? 'textSecondary' : 'textPrimary'}>
        {offering.offeringType} - {offering.offeringSubType}
      </Typography>
      <Typography variant="body2" color={offering.archived ? 'textSecondary' : 'textPrimary'}>
        Pricing Link: <Link href={offering.pricingLink}>{offering.pricingLink}</Link>
      </Typography>
      <Typography variant="body2" color={offering.archived ? 'textSecondary' : 'textPrimary'}>
        Pricing Summary: {offering.pricingSummary}
      </Typography>
      {combinedData.map((data, index) => (
        <Box key={index} sx={{marginTop: 1 }}>
          <Typography variant="body2" sx={{marginBottom: 1}} color={offering.archived ? 'textSecondary' : 'textPrimary'}>
            <Link href={data.url}>{data.url}</Link>
          </Typography>
          {data.statements.map((statement, idx) => (
            <Typography key={idx} variant="body2" sx={{marginLeft: 2}} color={offering.archived ? 'textSecondary' : 'textPrimary'}>
              {statement.statement}
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  );  
};

export default Offering;
