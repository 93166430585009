import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { getSearch } from '../utils/get-search';
import SearchChat from "../components/SearchChat";
import SearchResults from "../components/SearchResults";
import SearchQuery from "../components/SearchQuery";
import Grid from '@mui/material/Grid';
import AcenIcon from '../assets/images/acen-icon.png';
import AcenLogo from '../assets/images/acen-logo-256.png';
import UserIcon from '../assets/images/user.png';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import LogRocket from 'logrocket';
import FadeInOut from "../components/FadeInOut";

const Search = () => {
  const { searchId } = useParams();
  const [ search, setSearch ] = useState({});
  const [ priority, setPriority ] = useState([3, 2, 1]);
  const [ toggleChat, setToggleChat ] = useState(false);
  const [ toggleQuery, setToggleQuery ] = useState(false);
  const [ toggleResults, setToggleResults ] = useState(true);
  const [ toggleCount, setToggleCount ] = useState(1);
  const [ renderChat, setRenderChat ] = useState(false);
  const [ renderQuery, setRenderQuery ] = useState(false);
  const [ renderResults, setRenderResults ] = useState(true);
  const [ loading, setLoading ] = useState(true);
  
  // load LogRocket only when search component mounts
  useEffect(() => {
    LogRocket.init('ghxx1r/acenone');
  }, []);

  const getBreakpoint = (width) => {
    const breakpoint = width >= 1200 ? "lg" : width >= 900 ? "md" : "sm" ;
    return breakpoint;
  };
  
  const [ sizeBreakpoint, setSizeBreakpoint ] = useState(getBreakpoint(window.innerWidth));

  const changePriority = (newPriority) => {
    const newPriorityArray = [...priority]; // Create a copy of the original priority array
    const selectedIndex = newPriorityArray.indexOf(newPriority); // Find the index of the selected priority
  
    if (selectedIndex !== -1) {
      // If the selected priority exists in the array
      newPriorityArray.splice(selectedIndex, 1); // Remove the selected priority from the array
      newPriorityArray.unshift(newPriority); // Add the selected priority at the beginning of the array
    }
    console.log(newPriority);
    setPriority(newPriorityArray); // Update the state with the new priority array
  };

  const handlePanelChange = (panel) => {
    changePriority(panel);
    if (panel === 1) {
      if (toggleChat) {
        setToggleQuery(false);
        setToggleResults(false);
      } else {
        setToggleChat(!toggleChat);
      }
    } else if (panel === 2) {
      if (toggleQuery) {
        setToggleChat(false);
        setToggleResults(false);
      } else {
        setToggleQuery(!toggleQuery);
      }
    } else if (panel === 3) {
      if (toggleResults) {
        setToggleChat(false);
        setToggleQuery(false);
      } else {
        setToggleResults(!toggleResults);
      }
    }
  } 

  const panelMenu = 
      <div class="panelMenu">
        <Button sx={[{color:grey[700]}, renderChat ? {fontWeight:'bold'} : null]} onClick={() => handlePanelChange(1)}>Chat</Button>
        <Button sx={[{color:grey[700]}, renderQuery ? {fontWeight:'bold'} : null]} onClick={() => handlePanelChange(2)}>Query</Button>
        <Button sx={[{color:grey[700]}, renderResults ? {fontWeight:'bold'} : null]} onClick={() => handlePanelChange(3)}>Results</Button>
      </div>
      ;

  useEffect(() => {
    const handleWindowResize = () => setSizeBreakpoint(getBreakpoint(window.innerWidth));
    
    window.addEventListener("resize", handleWindowResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);  // Empty dependency array means this effect runs once on mount, and cleanup runs on unmount

  useEffect(() => {
    const loadSearch = async () => {
      const search = await getSearch(searchId);
      setSearch(search);
      setLoading(false);
    };
    loadSearch();
  }, [searchId]);

  useEffect(() => {
    let newToggleCount = 0;
    if (toggleChat) newToggleCount++;
    if (toggleQuery) newToggleCount++;
    if (toggleResults) newToggleCount++;
    setToggleCount(newToggleCount);

    if (sizeBreakpoint === "lg") {
      setRenderChat(toggleChat);
      setRenderQuery(toggleQuery);
      setRenderResults(toggleResults);

    } else if (sizeBreakpoint === "md") {
      if (newToggleCount === 3) {
        const currentPriorities = priority.slice(0, 2);
        setRenderChat(currentPriorities.includes(1));
        setRenderQuery(currentPriorities.includes(2));
        setRenderResults(currentPriorities.includes(3));
      } else {
        setRenderChat(toggleChat);
        setRenderQuery(toggleQuery);
        setRenderResults(toggleResults);
      }
    } else {
      const currentPriorities = priority.slice(0,1);
      setRenderChat(currentPriorities.includes(1));
      setRenderQuery(currentPriorities.includes(2));
      setRenderResults(currentPriorities.includes(3));
    }

  }, [priority, sizeBreakpoint, toggleChat, toggleQuery, toggleResults]);

  const searchContent = search.chat && search.user && search.statements && search.results ?
    <div class="searchArea">
        <table class="header">
          <tbody>
            <tr>
              <td class="acenLogo" width="40px">
                <div class="acenLogo"><img width="32px" height="32px" alt="acenOne" src={AcenIcon}/></div>
              </td>
              <td class="menu">
                {panelMenu}
              </td>
              <td class="user">
                <img width="32px" height="32px" alt="user" src={UserIcon}/>
              </td>
            </tr>
          </tbody>
        </table>
        <div class={`searchAreaContent ${"toggleCount-"+toggleCount}`}>
          <Grid sx={{ flexGrow: 1 }} container spacing={1}>
            <Grid container justifyContent="center" spacing={1}>
              {renderChat ? <Grid item xs={12} sm={12} md={6} lg={4}>
                <SearchChat chat={search.chat} user={search.user} />
              </Grid> : null}
              {renderQuery ? <Grid item xs={12} sm={12} md={6} lg={4}>
                <SearchQuery statements={search.statements} />
              </Grid> : null}
              {renderResults ? <Grid item xs={12} sm={12} md={6} lg={4}>
                <SearchResults results={search.results} scaffold={search.scaffold} />
              </Grid> : null}
            </Grid>
          </Grid>
        </div>
      </div>
  : null;
    
  const searchRender = () => {
    return (
      <div class="searchAreaWrapper">
        {searchContent}
        <FadeInOut show={loading} duration={500}>
          <div class="loading">
            <div class="loadingInnerShadow">
              <div class="loadingInner">
                <img width="256px" height="256px" alt="acenOne" src={AcenLogo}/>
                <div class="loadingText">Find Better Ways to Get the Job Done</div>
                <div class="loadingText">Search Loading...</div>
              </div>
            </div>
          </div>
        </FadeInOut>
      </div>
    );
  };

  return searchRender();
};

export default Search;