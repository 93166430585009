import React from 'react';
import SearchQueryStatement from './SearchQueryStatement';

const SearchQuery = (props) => {
  const { statements } = props;

  const statementComponents = statements.length > 0 ? statements.map((statement, key) => {
    return (<SearchQueryStatement key={key} statement={statement} />);
  }) : null;

  return (
    <div class="searchQuery">
      <div class="panelHeader">Query</div>
      <div class="content">
        {statementComponents}
      </div>
    </div>
  );
};

export default SearchQuery;