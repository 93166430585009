import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { collection, onSnapshot, doc, query as firestoreQuery, where } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { formatDistanceToNow } from 'date-fns';
import Offering from './Offering';

const Crawl = ({ crawl, siteId }) => {
  const [offerings, setOfferings] = useState([]);
  const [pageTasks, setPageTasks] = useState([]);
  const crawlDateTime = crawl.datetime.toDate();
  const timeSinceCrawl = formatDistanceToNow(crawlDateTime);

  useEffect(() => {
    const offeringsRef = collection(doc(db, 'sites', siteId, 'crawls', crawl.id), 'offerings');
    const unsubscribe = onSnapshot(offeringsRef, (snapshot) => {
      const newOfferings = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOfferings(newOfferings);
    });
    return () => {
      unsubscribe();
    };
  }, [siteId, crawl.id]);

  useEffect(() => {
    const pageTasksRef = collection(db, 'queue-page');
    const queryRef = firestoreQuery(
      pageTasksRef,
      where('domain', '==', siteId),
      where('crawlId', '==', crawl.id)
    );

    const unsubscribe = onSnapshot(queryRef, (snapshot) => {
      const newPageTasks = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPageTasks(newPageTasks);
    });

    return () => {
      unsubscribe();
    };
  }, [siteId, crawl.id]);

  return (
    <Box sx={{ padding: 2, border: '1px solid #ddd', marginBottom: 2, marginTop: 2 }}>
      <Typography variant="h6">Crawl ID: {crawl.id}</Typography>
      <Typography variant="body2" sx={{marginBottom: 2}}>Run {timeSinceCrawl} ago</Typography>
      {crawl.industry && crawl.industry.length > 0 && (
        <Typography variant="body2" sx={{marginBottom: 2}}>
          {crawl.industry.toString()}
        </Typography>
      )}
      {offerings.map((offering, index) => (
        <Offering key={index} offering={offering} crawlId={crawl.id} siteId={siteId} />
      ))}
      {/* Add this line to use pageTasks and remove the ESLint warning */}
      {/* Remove or comment it out if you're not ready to use pageTasks yet */}
      {pageTasks && pageTasks.length > 0 && pageTasks.map((pageTask, index) => (
        <Typography key={index} variant="body2" sx={{marginLeft: 2}} color="textPrimary">
          {pageTask.status} {pageTask.url && pageTask.url}
        </Typography>
      ))}
    </Box>
  );
};

export default Crawl;
