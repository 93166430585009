import React from 'react';
import { SearchProvider } from '../contexts/search.context';
import Search from '../components/s/Search';

const S = () => {
  return (
    <SearchProvider>
      <Search />
    </SearchProvider>
  );
};

export default S;
