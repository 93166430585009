import React, { useContext, useState, useEffect } from 'react';
import { SearchContext } from '../../contexts/search.context';
import { Box, Typography, Avatar } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import LinearProgress from '@mui/material/LinearProgress';
/* import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'; */
import ListItemText from '@mui/material/ListItemText';
/* import IconButton from '@mui/material/IconButton'; */
import ChatIcon from '../../assets/images/chat.png';
import UserIcon from '../../assets/images/user.png';
import SearchButton from './SearchButton';

const MessageWrapper = ({children, role}) => (
  <Box sx={{ 
    display: 'flex', 
    position: 'relative',
    backgroundColor: role === 'acen' ? '#ffffff' : '#f2f2f2', 
    padding: '24px',
    borderTop: '1px solid #e0e0e0',
  }}>
    <Avatar src={role === 'acen' ? ChatIcon : UserIcon} alt={`${role} icon`} sx={{ width: 24, height: 24 }} />
    <Box sx={{ marginLeft: 2, width: '100%' }}>
      {children}
    </Box>
  </Box>
)

const Statements = ({statements}) => (
  <>
    <Typography variant="body1" marginBottom={1}>
      It seems like you want to...
    </Typography>
    <List sx={{ width: '100%'}}>
      {statements.map((statement, key) => (
        <ListItem
          key={key}
          disablePadding
        >
{/*           <IconButton edge="start" aria-label="comments">
            <RemoveCircleIcon />
          </IconButton>
          <IconButton edge="start" aria-label="comments">
            <AddCircleIcon />
          </IconButton> */}
          <ListItemText id={key} primary={statement} />
        </ListItem>
      ))}
    </List>
  </>
)

const ChatMessage = ({ message, role, statements, messageObj, addEllipsis }) => {
  const { lastMessage, lastQuery, runQuery } = useContext(SearchContext);
  const [ellipsis, setEllipsis] = useState("");
  const [searchButton, setSearchButton] = useState("");
  const statementCount = statements?.length || 0;
  const statementPercent = statementCount < 6 ? statementCount / 5 * 100 : 100;
  const clarityOfNeed = 
    statementPercent < 40 ? "Unclear" :
    statementPercent < 81 ? "Somewhat Clear" : 
    "Very Clear";

  useEffect(() => {
    const lastMessageStatementsExists = lastMessage && lastMessage.statements;
    const statementsExists = statements && statements.length > 0;
    const lastMessageStatementsEqual = JSON.stringify(lastMessage?.statements) === JSON.stringify(statements);
    const lastQueryStatements = lastQuery?.statements?.map(statement => statement.statement);
    const lastQueryStatementsNotEqual = JSON.stringify(lastQueryStatements) !== JSON.stringify(statements);
    const lastQueryComplete = lastQuery?.status === 'complete';
  
    // this is the last message and it has statements, and it's not already about to start a query
    if (lastMessageStatementsExists && statementsExists && lastMessageStatementsEqual && message.toLowerCase() !== 'false') {
      // no query has been run yet
      if (!lastQueryStatements) {
        setSearchButton(
          <Box>
           <SearchButton onClick={runQuery}/>
          </Box>
        );
      // query has been run and is complete and statements are not equal to this message's statements 
      } else if (lastQueryStatementsNotEqual && lastQueryComplete) {
        setSearchButton(
          <Box>
           <SearchButton onClick={runQuery}/>
          </Box>
        );
      } else {
        setSearchButton("");
      }
    } else {
      setSearchButton("");
    }
  }, [lastMessage, statements, lastQuery, runQuery, messageObj, message, statementPercent]);

  useEffect(() => {
    if (addEllipsis) {
      const intervalId = setInterval(() => {
        setEllipsis((prev) => {
          if (prev.length < 3) {
            return prev + '.';
          } else {
            return '';
          }
        });
      }, 300);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [addEllipsis, setEllipsis]);

  return (
    <MessageWrapper role={role}>
      {role === 'acen' && (
        <>
          {statementCount > 0 && (
            <>
              <Statements statements={statements} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1, marginBottom: 1}}>
                <Typography variant="body2" sx={{fontStyle: "italic"}}>
                  Clarity of Needs
                </Typography>
                <Typography variant="body2" sx={{fontWeight: "bold", textTransform: "uppercase", color: "#696969"}}>
                  {clarityOfNeed}
                </Typography>
              </Box>  
              <LinearProgress variant="determinate" value={statementPercent} sx={{marginBottom: 4}}/>
            </>
          )}
        </>)}
        <Typography variant="body1">
          <span style={{whiteSpace: "pre-line"}}>
          {role === 'acen' && message === '' ? "Okay. Running search..."+ellipsis : message+ellipsis}
          </span>
        </Typography>
        {searchButton}
    </MessageWrapper>
  );
};

export default ChatMessage;
