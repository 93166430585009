import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/user.context";
import GoogleAuth from "../components/GoogleAuth";
import RejectAuth from "../components/RejectAuth";
import IndexSiteSubmit from "../components/index/IndexSiteSubmit";
import Site from "../components/index/Site";
import { Box } from "@mui/system";

const acceptedDomains = ["acen.ai"];

const ISite = () => {
  const { currentUser } = useContext(UserContext);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.user) {
      setLoggedIn(true);
    }
  }, [currentUser]);

  return (
    <Box p={2}>
      {loggedIn ? (
        acceptedDomains.includes(currentUser.domain) ? (
          <div>
            <IndexSiteSubmit />
            <Link to="/q">Task Queue</Link> - <Link to="/i">Main Index</Link>
            <Site />
          </div>
        ) : (
          <RejectAuth domain={currentUser.domain} />
        )
      ) : (
        <GoogleAuth />
      )}
    </Box>
  );
};

export default ISite;
