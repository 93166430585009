import axios from "axios";

export const getSearch = async (searchId) => {
  let search = {};
  let url = "https://us-central1-acenone-93e6b.cloudfunctions.net/getSearch-getSearch";

  await axios
    .get(url, {
      params: {
        searchId: searchId,
      },
    })
    .then((response) => {
      if (response.data) {
        search = response.data;

        // sort results by score
        search.results = search.results.map((result) => {
          if (result.score === undefined) {
            result.score = 0;
          }
          return result;
        });
        search.results.sort((a, b) => {
          return b.score - a.score;
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
  return search;
};
