import { Box, Typography, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, onSnapshot, collection, query, orderBy } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import Crawl from './Crawl';

const Site = () => {
  const { siteId } = useParams();
  const [site, setSite] = useState(null);
  const [currentCrawl, setCurrentCrawl] = useState(null);
  const [crawls, setCrawls] = useState([]);

  useEffect(() => {
    const siteRef = doc(db, 'sites', siteId);

    const unsubscribeSite = onSnapshot(siteRef, (snapshot) => {
      if (snapshot.exists()) {
        const siteData = {
          id: snapshot.id,
          ...snapshot.data(),
          lastUpdate: snapshot.data().lastUpdate?.toDate() || null,
        };

        setSite(siteData);
      } else {
        console.warn(`Site with ID ${siteId} does not exist`);
        // Handle the case where the site does not exist
      }
    });

    const crawlsRef = collection(siteRef, 'crawls');
    const crawlsQuery = query(crawlsRef, orderBy('datetime', 'desc'));
    const unsubscribeCrawls = onSnapshot(crawlsQuery, (snapshot) => {
      const newCrawls = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCrawls(newCrawls);
      if (newCrawls.length > 0) {
        setCurrentCrawl(newCrawls[0]); // Set the first result (most recent) as currentCrawl
      }
    });

    return () => {
      // Clean up both subscriptions
      unsubscribeSite();
      unsubscribeCrawls();
    };
  }, [siteId]);

  if (!site) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ marginTop: "20px" }}>
      <Typography variant="h4">
        {site.icon && <img src={site.icon} alt={site.orgName} style={{ width: '24px', height: '24px', marginRight: "10px" }} />}
        {site.id} - ({site.orgName})
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          {currentCrawl && (
            <Crawl crawl={currentCrawl} siteId={siteId}/>
          )}
        </Grid>
        <Grid item xs={4}>
          {crawls && crawls.length > 0 && (
            <Box>
              <Typography variant="h6">Crawl History</Typography>
              {crawls.map((crawl, index) => (
                <div key={index}>
                  <Typography variant="body1">
                    {crawl.datetime.toDate().toLocaleString()}
                    {crawl.archived ? (<span> - <i>(archived)</i></span>) : (<span> - <i>(current)</i></span>)}
                  </Typography>
                </div>
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Site;
