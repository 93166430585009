import React, { useContext, useEffect, useState } from 'react';
import { SearchContext } from '../../contexts/search.context';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Result from './Result';
import SearchButton from './SearchButton';
import Typography from '@mui/material/Typography';
import Subscribe from './Subscribe';
import RefreshIcon from '@mui/icons-material/Refresh';

const ResultsPanel = () => {
  const { lastQuery, lastResults, runQuery, currentStatements, searchState } = useContext(SearchContext);
  const [ refresh, setRefresh ] = useState("");
  const [ progress, setProgress ] =  useState(0);

  useEffect(() => {
    if (lastQuery && lastQuery.status) {
      if (lastQuery.status === 'pending') {
        setProgress(5);
      } else if (lastQuery.status === 'embedded') {
        setProgress(30);
      } else if (lastQuery.status === 'queried') {
        setProgress(60);
      } else if (lastQuery.status === 'sorted') {
        setProgress(80);
      } else if (lastQuery.status === 'complete') {
        setProgress(100);
      }

      if (lastQuery.status === 'complete' || lastQuery.status === 'timeout') {
        setRefresh(
          <RefreshIcon
            style={{ cursor: 'pointer' }}
            onClick={runQuery}
            sx={{marginLeft: 1, marginBottom: "-3px"}}
          />
        );
      } else {
        setRefresh("");
      }
    }
  }, [lastQuery, runQuery]);

  return (
    <Container sx={{ height: 'calc(100vh - 100px)', display: 'flex', flexDirection: 'column', justifyContent: 'top', overflowY: 'scroll' }}>
      <Box>
        {lastQuery && lastQuery.statements && lastQuery.status !== "complete" && lastQuery.status !== "timeout" && (
          <Box>
            <LinearProgress variant="determinate" value={progress} />
            <Typography variant="h6" sx={{marginTop: 1}}>Seaching...</Typography>
          </Box>
        )}
        {
          lastQuery && lastQuery.status !== "complete" && lastQuery.delayed ? (
            <Box sx={{ padding: "20px" }}>
              <h3>Taking longer than expected....</h3>
              <p>Network connections are running slower than normal, thank you for your patience.</p>
            </Box>
          ) : lastQuery && lastQuery.status === "timeout" ? (
            <Box sx={{ padding: "20px" }}>
              <h3>Query Network Timeout</h3>
              <SearchButton onClick={runQuery} label={"Try Again"} />
            </Box>
          ) : null
        }
        {lastResults && lastResults.results && lastResults.results.length > 0 && (
          <Box>
            <Typography variant="h6" sx={{marginBottom: 1}}>Get notified when we discover new tech relevant to your search.</Typography>
            <Subscribe />
            <Typography variant="h6" sx={{marginBottom: 1}}>Here is what we know about so far...
              {refresh}
            </Typography>
            {lastResults.results.map((result, key) => (
              <Result key={key} result={result} />
            ))}
          </Box>
        )}
        {lastQuery && lastQuery.status === "complete" && (!lastResults || !lastResults.results || (lastResults.results && lastResults.results.length === 0)) && (
          <Box sx={{paddingTop: "20px"}}>
            <Typography variant="h6" sx={{marginBottom: 1}}>No solutions found 
              {refresh}
            </Typography>
            <Typography variant="body" sx={{marginBottom: 2}}>If you would like me to notify when I discover new solutions relevant to your search, subscribe below. </Typography>
            <Subscribe />
          </Box>
        )}
        {(!lastQuery.id || lastResults.length === 0) && currentStatements && currentStatements.length > 0 && (
          <Box sx={{paddingTop: "20px"}}>
            {currentStatements.map((statement, key) => (
              <Box key={key} marginBottom={1}>
                <Chip label={statement} size="small" />
              </Box>
            ))}
            {searchState !== "searching" && 
            searchState !== "thinking" && 
            (!lastQuery.statements ||
            JSON.stringify(currentStatements) !== JSON.stringify(lastQuery.statements.map((statement) => statement.statement))) && (
              <SearchButton onClick={runQuery} progress={currentStatements.length / 8 * 100} />
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ResultsPanel;
