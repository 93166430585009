import React, { useContext, useEffect, useState, useRef } from 'react';
import { Grid, Button, useMediaQuery, useTheme } from '@mui/material';
import { SearchContext } from '../../contexts/search.context';
import Header from './Header'; 
import ChatPanel from './ChatPanel';
import ResultsPanel from './ResultsPanel';
import { useParams } from 'react-router-dom';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const Search = () => {
  const { lastQuery, setSearchId, searchId } = useContext(SearchContext);
  const { sId } = useParams();
  const [showChat, setShowChat] = useState(true);
  const [showResults, setShowResults] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [queryExists, setQueryExists] = useState(false);
  const buttonClass = showChat ? 'panel-toggle-bottom' : 'panel-toggle-top';

  useEffect(() => {
    if (sId && sId !== searchId) setSearchId(sId);
  }, [sId, setSearchId, searchId]);

  const hasLastQueryUpdated = useRef(false);

  useEffect(() => {
    if (lastQuery && lastQuery.status && !hasLastQueryUpdated.current) {
      setShowChat(false);
      setShowResults(true);
      setQueryExists(true);
      hasLastQueryUpdated.current = true; // Set the ref to true after handling the first update
    }
  }, [lastQuery]);

  const togglePanels = () => {
    setShowChat(!showChat);
    setShowResults(!showResults);
  };

  return (
    <>
      <Header />
      <Grid container spacing={2} style={{ marginTop: '48px' }} sx={{ justifyContent: 'center' }}>
        {(!mobile || showChat) && (
          <Grid item xs={12} lg={6}>
            <ChatPanel queryExists={queryExists} />
          </Grid>
        )}
        {mobile && queryExists && (
          <Button className={buttonClass} onClick={togglePanels}>
            {showChat ? 
              <>
                <KeyboardDoubleArrowDownIcon />
                Results
                <KeyboardDoubleArrowDownIcon />
              </> : 
              <>
                <KeyboardDoubleArrowUpIcon />
                Chat
                <KeyboardDoubleArrowUpIcon />
              </>
            }
          </Button>
        )}
        {(lastQuery && lastQuery.status && (!mobile || showResults)) && (
          <Grid item xs={12} lg={6}>
            <ResultsPanel queryExists={queryExists} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Search;
