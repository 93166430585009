import React from 'react';
import AcenIcon from '../assets/images/acen-icon.png';
import UserIcon from '../assets/images/user.png';

const SearchChatDialog = (props) => {
  const { dialog } = props;

  const isUser = dialog.actor === "user";

  return (
    <div class={isUser ? "dialogUser" : "dialogAcen"}>
      <table>
        <tbody>
          <tr>
            <td class="actor">
              <div class="actor">
                {isUser ? <img width="32px" height="32px" alt="user" src={UserIcon}/> : 
                <img width="32px" height="32px" alt="acenOne" src={AcenIcon}/>}
              </div>
            </td>
            <td>
              <div class="message">{dialog.message}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SearchChatDialog;