import { useContext } from "react";
import { signInWithGoogle } from "../utils/firebase";
import Button from "@mui/material/Button";
import { UserContext } from "../contexts/user.context";

function GoogleAuth() {
  const { setCurrentUser } = useContext(UserContext);

  const signIn = async () => {
    const { user } = await signInWithGoogle();
    let domain = "";
    if (user && user.email && user?.email.length > 0) {
      domain = user.email.substring(
        user.email.indexOf("@") + 1
      );
    }
    setCurrentUser({user:user, domain: domain});
  };

  return (
    <div>
      <Button variant="contained" onClick={signIn}>
        Sign in with Google
      </Button>
    </div>
  );
}

export default GoogleAuth;