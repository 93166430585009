import React from 'react';
import SearchResultStatement from './SearchResultStatement';

const SearchResult = (props) => {
  const { result } = props;

  const statementComponents = result.statements && result.statements.length > 0 ? result.statements.map((statement, key) => {
    return (<SearchResultStatement key={key} statement={statement.statement} explanation={statement.explanation} index={key} />);
  }) : null;

  return (
    <div class="searchResult">
      <a href={result.attributes.url}>
        <table>
          <tbody>
            <tr>
              <td class="icon">
                <div class="icon">
                  <img width="32px" height="32px" alt={result.attributes.title} src={result.attributes.icon} />
                </div>
              </td>
              <td class="searchResultHeader">
                <div class="title">{result.attributes.title}</div>
                <div class="subtitle">{result.attributes.subtitle}</div>
                {result.attributes.pricing ? <div class="pricing">{result.attributes.pricing}</div> : null}
              </td>
            </tr>
          </tbody>
        </table>
      </a>
      {result.prefix ? <div class="searchResultPrefix">{result.prefix}</div> : null}
      <div class="statements">
        {statementComponents}
      </div>
      {result.suffix ? <div class="searchResultPrefix">{result.suffix}</div> : null}
    </div>
  );
};

export default SearchResult;