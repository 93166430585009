import React, { useContext } from 'react';
import { UserContext } from "../../contexts/user.context";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

const Result = ({ result }) => {
  const { currentUser } = useContext(UserContext);
  const { score, orgName, domain, name, url, content, pricingLink, pricingSummary, offeringType, offeringSubType, icon, statements, multiple, customDescription } = result;
  console.log(orgName);
  console.log(result);
  const statementEntries = Object.entries(statements || {}).sort((a, b) => b[1].score - a[1].score);

  const urlParams = new URLSearchParams(window.location.search);
  const debug = urlParams.has('debug');

  const title = multiple ? orgName : name;

  return (
    <Box sx={{ padding: 2, border: '1px solid #ccc', marginBottom: 2, borderRadius: 1 }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {icon && <img src={icon} alt={name} style={{ width: '32px', height: '32px' }} />}
        <Typography variant="h6">
          <Link href={multiple ? url : `https://${domain}`} target="_blank" rel="noopener">{title}</Link>
        </Typography>
        {orgName && !multiple && <Typography variant="body2">
          (<Link href={`https://${domain}`} target="_blank" rel="noopener">{orgName}</Link>)
        </Typography>}
      </Stack>
      <Typography variant="body2" gutterBottom>
        {offeringType && `${offeringType} / `}
        {offeringSubType}
      </Typography>
      {pricingSummary && <Typography variant="body2" gutterBottom>
        <Link href={pricingLink} target="_blank" rel="noopener">{pricingSummary}</Link>
      </Typography>}
      <Typography variant="body2" gutterBottom>
        {!customDescription ? "Generating description..." :
          customDescription && customDescription !== "0" ? customDescription : 
          customDescription === "0" && content && content !== "" ? content : 
            statementEntries[0] && statementEntries[0][1] && statementEntries[0][1].matchStatement ? statementEntries[0][1].matchStatement : 
            "No description available"}
      </Typography>
      {debug && currentUser && currentUser.user && (
        <Box sx={{ marginTop: 2 }}>
        <Typography variant="body2" color="text.secondary">
          Result Score: {score}
        </Typography>

        {statementEntries.map(([key, statement], index) => (
        <Box key={key} sx={{ marginTop: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Query Statement: {statement.queryStatement}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Match Statement: {statement.matchStatement}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Score: {statement.score}
          </Typography>
          {statement.category && (
            <Typography variant="body2" color="text.secondary">
              Category: {statement.category}
            </Typography>
          )}
        </Box>
        ))}
        </Box>
      )}
    </Box>
  );
};

export default Result;
