import { signOut } from "../utils/firebase";
import { useContext } from "react";
import { UserContext } from "../contexts/user.context";
import Button from "@mui/material/Button";

const RejectAuth = (props) => {
  const { currentUser, setCurrentUser } = useContext(UserContext);

  const signOutHandler = async () => {
    await signOut();
    setCurrentUser(null);
  };

  return (
    <div>
      {`Sorry, but google account domain (${currentUser.domain}) that you signed in with doesn't have permission to access this part of the application.`}
      <div>
        <Button variant="contained" onClick={signOutHandler}>
          SIGN OUT
        </Button>
      </div>
    </div>
  );
};

export default RejectAuth;
