import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/user.context";
import GoogleAuth from "../components/GoogleAuth";
import RejectAuth from "../components/RejectAuth";
import IndexSiteSubmit from "../components/index/IndexSiteSubmit";
import TaskList from "../components/index/TaskList";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";

const acceptedDomains = ["acen.ai"];

const I = () => {
  const { currentUser } = useContext(UserContext);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.user) {
      setLoggedIn(true);
    }
  }, [currentUser]);

  return (
    <Box p={2}>
      {loggedIn ? (
        acceptedDomains.includes(currentUser.domain) ? (
          <div>
            <Typography variant="h4">
              Indexing Task Queue
            </Typography>
            <IndexSiteSubmit />
            <Link to="/i">Index</Link>
            <TaskList />
          </div>
        ) : (
          <RejectAuth domain={currentUser.domain} />
        )
      ) : (
        <GoogleAuth />
      )}
    </Box>
  );
};

export default I;
