import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { functions, db } from "../../utils/firebase";
import { httpsCallable } from "firebase/functions";
import { doc, getDoc } from 'firebase/firestore';

const IndexSiteSubmit = () => {
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);  // Start loading

    let parsedUrl;
    try {
      parsedUrl = new URL(inputValue);
    } catch (error) {
      console.error("Invalid URL");
      setError("Invalid URL! Please enter a valid URL."); // Set the error message
      setIsLoading(false);
      return;
    }
  
    const hostname = parsedUrl.hostname;
    let strippedHostname = hostname;
    if (hostname.startsWith("www.") || hostname.startsWith("app.")) {
      strippedHostname = hostname.split(".").slice(1).join(".");
    }

    const docRef = doc(db, 'sites', strippedHostname);
    const docSnap = await getDoc(docRef);
    setError(null);
    
    if (docSnap.exists()) {
      // If the document already exists, ask for confirmation
      setOpenDialog(true);
    } else {
      // If the document doesn't exist, call the function immediately
      callFunction();
    }
  };

  const callFunction = () => {
    const submitUrl = httpsCallable(functions, 'start-submitUrl');
    submitUrl({ url: inputValue })
      .then((result) => {
        console.log(result.data); // {orgName: ...}
        setIsLoading(false);  // Stop loading
      })
      .catch((error) => {
        console.error("Error calling receiveURL:", error);
        setIsLoading(false);  // Stop loading
      });

    setInputValue("");  // Clear input field immediately
    setOpenDialog(false);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setIsLoading(false);  // Stop loading
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <TextField
          value={inputValue}
          onChange={handleInputChange}
          variant="outlined"
          label="Submit URL"
        />
        <Button type="submit" variant="contained" color="primary" sx={{marginLeft: 2}} disabled={isLoading}>
          Submit
          {isLoading && <CircularProgress size={24} sx={{position: 'absolute'}} />}
        </Button>
      </form>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"The site has already been indexed. Do you want to re-index the site?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={callFunction} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IndexSiteSubmit;
