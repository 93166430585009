import React from 'react';
import SearchChatBox from './SearchChatBox';
import SearchChatDialog from './SearchChatDialog';

const SearchChat = (props) => {
  const { chat, user } = props;

  const chatComponents = chat.length > 0 ? chat.map((dialog, key) => {
    return (
      <SearchChatDialog key={key} dialog={dialog} user={user} />
    );
  }) : null;
  return (
    <div class="searchChat">
      <div class="panelHeader">Chat</div>
      <div class="content">
        <div class="chatComponents">
          {chatComponents}
        </div>
      </div>
      <SearchChatBox />
    </div>
  );
};

export default SearchChat;