import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Search from "./routes/Search";
import I from "./routes/I";
import ISite from "./routes/ISite";
import S from "./routes/S";
import Q from "./routes/Q";
import Construction from "./routes/landing/Construction";
import LogRocket from "logrocket";

import "./App.css";

// Create your custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#005DAC",
    },
    secondary: {
      main: "rgb(255, 208, 64)",
    },
  },
});

function ExternalRedirect({ to }) {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null; // This component renders nothing, just performs a redirection
}

function App() {
  useEffect(() => {
    LogRocket.init("ghxx1r/acenone");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {/** landing routes */}
        <Route path="/" element={<Construction />} />
        <Route
          path="/construction"
          element={<ExternalRedirect to="https://contech.cam-do.com" />}
        />
        {/** functional routes */}
        <Route path="/search/:searchId" element={<Search />} />
        <Route path="/i" element={<I />} />
        <Route path="/i/:siteId" element={<ISite />} />
        <Route path="/q" element={<Q />} />
        <Route path="/s" element={<S />} />
        <Route path="/s/:sId" element={<S />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
