import { createContext, useState, useEffect } from 'react';
import { collection, query, onSnapshot, orderBy, limit, getDocs, addDoc, doc, updateDoc } from "firebase/firestore";
import { db } from '../utils/firebase';
import { useNavigate } from 'react-router-dom';

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchId, setSearchId] = useState(undefined);
  const [lastMessage, setLastMessage] = useState([]);
  const [lastQuery, setLastQuery ] = useState([]);
  const [lastResults, setLastResults] = useState([]);
  const [currentStatements, setCurrentStatements] = useState([]);
  const [searchState, setSearchState ] = useState("open");
  const [chatBoxFocus, setChatBoxFocus] = useState(false);
  const [ seg ] = useState("construction");
  const navigate = useNavigate();

  const startSearch = async () => {
    const docRef = await addDoc(collection(db, 's'), {
      timestamp: new Date(),
      seg: seg,
    });

    const searchId = docRef.id;
    setSearchId(searchId);
    navigate(`/s/${searchId}`);
    return searchId;
  };

  useEffect(() => {
    if (searchId) {
      const queriesCollection = collection(db, `/s/${searchId}/queries`);
      const q = query(queriesCollection, orderBy("key", "desc"), limit(1));
  
      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (snapshot.empty) return;
        const newLastQuery = snapshot.docs[0].data();
        newLastQuery.id = snapshot.docs[0].id;
        setLastQuery(newLastQuery);
      });
  
      const checkTimeout = async () => {
        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
          const queryData = snapshot.docs[0].data();
          const queryTimestamp = queryData.timestamp.toDate();
          const currentTime = new Date();
          const timeDifferenceInSeconds = (currentTime - queryTimestamp) / 1000;
      
          if (queryData.status !== "complete" && queryData.status !== "timeout") {
            if (timeDifferenceInSeconds > 540) {
              const docRef = doc(db, `/s/${searchId}/queries`, snapshot.docs[0].id);
              await updateDoc(docRef, { status: "timeout" });
              setLastQuery({ ...queryData, id: snapshot.docs[0].id, status: "timeout" });
            } else if (timeDifferenceInSeconds > 120) {
              setLastQuery({ ...queryData, id: snapshot.docs[0].id, delayed: true });
            }
          }
        }
      };
  
      checkTimeout();
      // also check for timeout every minute
      const intervalId = setInterval(checkTimeout, 60 * 1000);
  
      // Cleanup subscription and interval on unmount
      return () => {
        unsubscribe();
        clearInterval(intervalId);
      };
    }
  }, [searchId]);


  useEffect(() => {
    if (searchId) {
      const resultSetsCollection = collection(db, `/s/${searchId}/resultSets`);
      const q = query(resultSetsCollection, orderBy("key", "desc"), limit(1));
  
      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (snapshot.empty) return;
        const latestResultSet = snapshot.docs[0];
        const resultSetData = latestResultSet.data();
        const resultsSubCollection = collection(latestResultSet.ref, 'results');
        
        // Query the results subcollection and order by score
        const resultsQuery = query(resultsSubCollection, orderBy("score", "desc"));
        onSnapshot(resultsQuery, (resultsSnapshot) => {
          if (resultsSnapshot.empty) return;
          
          // Map the results into an array, including the document IDs
          const resultsArray = resultsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
  
          // Merge the resultSet doc with the sorted results
          const newLastResults = {
            ...resultSetData,
            id: latestResultSet.id,
            results: resultsArray,
          };
  
          // Set the merged object as the new last results
          setLastResults(newLastResults);
        });
      });
  
      // Cleanup subscription on unmount
      return () => unsubscribe();
    }
  }, [searchId]);

  const addMessage = async (message, key) => {
    let currentSearchId = searchId;
    if (searchId === undefined) {
      currentSearchId = await startSearch();
    }
    if (currentSearchId) {
      await addDoc(collection(db, `s/${currentSearchId}/userMessages`), {
        timestamp: new Date(),
        message: message,
        key: key,
        role: "user",
      });
    }
  };

  const runQuery = async () => {
  
    const queriesCollection = collection(db, `/s/${searchId}/queries/`);
  
    // Query last document and check status
    const q = query(queriesCollection, orderBy("key", "desc"), limit(1));
    const querySnapshot = await getDocs(q);
  
    const statements = lastMessage.statements.map((statement) => ({statement: statement, vector: []}));
  
    let key = 1;
    if (!querySnapshot.empty) {
      const lastDoc = querySnapshot.docs[0];
      if (lastDoc.data().status !== "complete" && lastDoc.data().status !== "timeout") {
        return; // A query is already running, so return
      }
  
      // If the last query status is complete, set the key of the new query to the previous query key + 1
      key = Number(lastDoc.data().key) + 1;
    }
  
    // Create a new document
    await addDoc(queriesCollection, {
      searchId: searchId,
      status: "pending",
      statements: statements,
      messageId: lastMessage.id,
      key: key,
      timestamp: new Date(),
    });
  };

  return (
    <SearchContext.Provider value={{ 
      lastQuery, 
      lastMessage, 
      lastResults, 
      setLastMessage, 
      searchId, 
      setSearchId, 
      addMessage, 
      runQuery,
      seg,
      currentStatements,
      setCurrentStatements,
      searchState,
      setSearchState, 
      chatBoxFocus,
      setChatBoxFocus,
    }}>
      {children}
    </SearchContext.Provider>
  );
};
