import React, { useContext, useState, useEffect, useRef } from 'react';
import { SearchContext } from '../../contexts/search.context';
import { db } from '../../utils/firebase';
import ChatSubmitBox from './ChatSubmitBox';
import ChatMessage from './ChatMessage';
import { Box } from '@mui/system';
import { useMediaQuery, useTheme } from '@mui/material';
import { collection, onSnapshot } from "firebase/firestore"; 
import { isMobile } from 'react-device-detect';

const ChatPanel = (props) => {
  const { queryExists } = props;
  const { 
    lastMessage, 
    setLastMessage, 
    searchId, 
    addMessage, 
    lastQuery, 
    seg, 
    setCurrentStatements,
    setSearchState,
    searchState, 
    chatBoxFocus,
  } = useContext(SearchContext);
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down('lg'));
  const StartingMessage = {
    message: `Welcome! Let's find the perfect${seg ? ' ' + seg : ''} solution for you. 

    Start by telling me about some unmet need or unsolved problem you have, or a goal or job you wish was easier to accomplish. I will ask some questions to make sure I understand, and then help you find relevant solutions.`,
    role: "acen",
    key: 0,
  };
  const [userMessages, setUserMessages] = useState([]);
  const [acenMessages, setAcenMessages] = useState([]);
  const [newMessageKey, setNewMessageKey] = useState(1);
  const endOfMessagesRef = useRef(null);

  useEffect(() => {
    if (searchId) {
      const userMessagesRef = collection(db, `s/${searchId}/userMessages`);
      const acenMessagesRef = collection(db, `s/${searchId}/acenMessages`);

      const unsubscribeUserMessages = onSnapshot(userMessagesRef, (snapshot) => {
        const newUserMessages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUserMessages(newUserMessages);
      });

      const unsubscribeAcenMessages = onSnapshot(acenMessagesRef, (snapshot) => {
        const newAcenMessages = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // sort by key desc and then choose the first one
        if (newAcenMessages.length > 0) {
          const newLastMessage = newAcenMessages.sort((a, b) => b.key - a.key)[0];

          if (newLastMessage.statements?.length > 0) {
            setCurrentStatements(newAcenMessages[0].statements);
          }
        }

        setAcenMessages(newAcenMessages);
      });

      // Clean up the listeners when searchId changes or component unmounts
      return () => {
        unsubscribeUserMessages();
        unsubscribeAcenMessages();
      };
    }
  }, [searchId, setCurrentStatements]);

  const messages = [StartingMessage, ...userMessages, ...acenMessages].sort((a, b) => a.key - b.key);

  useEffect(() => {
    const state = 
      (lastQuery && lastQuery.status && lastQuery.status !== "complete" && lastQuery.status !== "timeout") ||
      (lastMessage.role === "acen" && lastMessage.message === "0" && 
      (!lastQuery.status || (lastQuery.status !== "complete" && lastQuery.status !== "timeout"))) ? "searching" : 
      lastMessage && lastMessage.role === "user" ? "thinking" :
      "open";
  
    setSearchState(state);
  }, [lastQuery, lastMessage, setSearchState]);

  useEffect(() => {
    if (messages && messages.length > 1) {
      // Check if the last message is an acenMessage
      const newLastMessage = messages[messages.length - 1];
      if (newLastMessage) {
        setLastMessage(newLastMessage);
      }
    }
  }, [messages, setLastMessage]);

  const lastMessageKey = messages[messages.length - 1]?.key;
  if (lastMessageKey !== undefined && lastMessageKey >= newMessageKey) {
    setNewMessageKey(lastMessageKey + 1);
  }

  const handleChatSubmit = (message) => {
    addMessage(message, newMessageKey);
  };

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollTop = endOfMessagesRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Box ref={endOfMessagesRef}
      sx={{ 
        height: 
          isMobile ? // is on a mobile device
            chatBoxFocus ? '100%' : // is a mobile device and chat box is in focus (mobile keyboard UI open)
              queryExists ? 'calc(100dvh - 101px)' : // is a mobile device and chat box is not in focus, but a query exists
              'calc(100dvh - 64px)' : // is a mobile device and chat box is not open and no query exists
          mobileSize ? // is on a desktop device and the screen size is less than large
            queryExists ? 'calc(100dvh - 101px)' : // is a desktop device with a small screen size and a query exists
              'calc(100dvh - 64px)' : // is a desktop device with a small screen size and no query exists
              'calc(100dvh - 64px)', // is a desktop device with a large screen size,
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between', 
        overflowY: 'scroll',
      }}>
      <Box 
        sx={{
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'flex-start',
        }}>
        {messages.map((message) => {
          return (
            <ChatMessage key={message.key} message={message.message} role={message.role} statements={message.statements}/>
          );
        })}
        {lastMessage && lastMessage.role === "user" && (
          <ChatMessage key={lastMessage.key+1} message="Thinking" role="acen" addEllipsis={true}/>
        )}
        
        <Box sx={{marginBottom: "60px"}} />
      </Box>
      <ChatSubmitBox state={searchState} onSubmit={handleChatSubmit}/>
    </Box>
  );
};

export default ChatPanel;
