import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Subscribe = () => {
  const { searchId } = useParams();
  const [email, setEmail] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [open, setOpen] = useState(false);

  const handleSubscribe = async () => {
    try {
      const subscriptionDocRef = doc(db, 'subs', email);
      const subscriptionSnapshot = await getDoc(subscriptionDocRef);

      if (subscriptionSnapshot.exists()) {
        const subscriptions = subscriptionSnapshot.data()?.subscriptions || [];

        if (!subscriptions.includes(searchId)) {
          subscriptions.push(searchId); // Add the searchId to the array
          await setDoc(subscriptionDocRef, { subscriptions }, { merge: true }); // Use setDoc with merge option
          handleSuccess();
        } else {
          handleFailure('You are already subscribed.');
        }
      } else {
        await setDoc(subscriptionDocRef, {
          subscriptions: [searchId],
        }, { merge: true }); // Use setDoc with merge option
        handleSuccess();
      }
    } catch (error) {
      console.log(error);
      handleFailure('Network error');
    }
  };

  const handleSuccess = () => {
    setSnackbarMessage(`Subscribed ${email}`);
    setSnackbarSeverity('success');
    setEmail(''); // Clear the email text field
    setOpen(true);
  };

  const handleFailure = (message) => {
    setSnackbarMessage(message);
    setSnackbarSeverity('error');
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      marginBottom="10px"
      marginTop="10px"
    >
      <TextField
        label="Email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button onClick={handleSubscribe} sx={{ marginLeft: 2 }}>Subscribe</Button>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Subscribe;
