import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import Box from '@mui/material/Box';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';

const IndexList = () => {
  const [sites, setSites] = useState([]);

  useEffect(() => {
    const unsubscribeSites = onSnapshot(collection(db, 'sites'), (snapshot) => {
      let newSites = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        lastUpdate: doc.data().lastUpdate?.toDate() || null
      }));

      // Sort by lastUpdated, with nulls at the end
      newSites.sort((a, b) => {
        if (a.lastUpdate === null) return 1;
        if (b.lastUpdate === null) return -1;
        return b.lastUpdate.getTime() - a.lastUpdate.getTime();
      });

      setSites(newSites);
    });

    return () => {
      // Clean up sites subscription
      unsubscribeSites();
    };
  }, []); // Run useEffect only once when the component is mounted

  return (
    <Box sx={{ marginTop: "20px" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Domain</TableCell>
            <TableCell>Index Page</TableCell>
            <TableCell>Vendor Name</TableCell>
            <TableCell>Last Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sites.map(site =>           
            <TableRow key={site.id}>
              <TableCell>
                {site.icon && <img src={site.icon} alt={site.orgName} style={{ width: '16px', height: '16px', marginRight: "10px" }} />}
                <a href={`https://${site.id}`} target="_blank" rel="noopener noreferrer">
                  {site.id}
                </a>
              </TableCell>
              <TableCell><Link to={site.id}>View</Link></TableCell>
              <TableCell>{site.orgName}</TableCell>
              <TableCell>{site.lastUpdate?.toLocaleString()}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default IndexList;
